<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box" id="modal">
        <div class="columns is-mobile">
          <div class="column">
            <p class="subtitle has-text-primary">Column header check</p>
          </div>
          <div class="column is-narrow">
            <a class="has-text-danger" @click="close">Close</a>
          </div>
        </div>
        <div v-if="noWorksheet" class="content">
          <p>
            Multi couldn't find a worksheet in your workbook. Please try copying
            the data to a new xlsx file and try again.
          </p>
        </div>
        <div v-else-if="noInputs && !duplicates" class="content">
          <p>
            Multi couldn't find any inputs in your sheet. Please add one of the
            options below to get your valuations:
          </p>
          <p class="is-size-5 has-text-info">Input options</p>
          <span class="box content has-background-light">
            <span class="icon has-text-success">
              <i class="far fa-check"></i>
            </span>
            <span class="has-text-grey">VRM</span>
          </span>
          <span class="box content has-background-light">
            <span class="icon has-text-success">
              <i class="far fa-check"></i>
            </span>
            <span class="has-text-grey">IDS code</span>
          </span>
          <span class="box content has-background-light">
            <span class="icon has-text-success">
              <i class="far fa-check"></i>
            </span>
            <span class="has-text-grey">Fleet Net code</span>
          </span>
          <span class="box content has-background-light">
            <div class="has-text-right has-text-grey is-size-7">
              *all 3 inputs are required to use Cazana ID
            </div>
            <span class="icon has-text-success">
              <i class="far fa-check"></i>
            </span>
            <span class="has-text-grey">Cazana ID</span>
            <span class="icon has-text-success">
              <i class="far fa-check"></i>
            </span>
            <span class="has-text-grey">Mileage</span>
            <span class="icon has-text-success">
              <i class="far fa-check"></i>
            </span>
            <span class="has-text-grey">First registration</span>
          </span>
        </div>
        <div v-if="!noInputs && !noOutputs" class="content">
          <p>
            Multi has found the following headers in your sheet. Please click
            'Confirm' if you're happy with them:
          </p>
          <p class="is-size-5 has-text-info">Inputs</p>
          <span class="box content has-background-light">
            <div
              v-for="(headerObject, index) in headers"
              :key="index + 'Inputs'"
            >
              <div
                class="column is-narrow"
                v-if="headerObject.type === 'Inputs'"
              >
                <span class="icon has-text-success">
                  <i class="far fa-check"></i>
                </span>
                <span class="has-text-grey">{{ headerObject.header }}</span>
              </div>
            </div>
          </span>
          <p />
          <p class="is-size-5 has-text-info">Outputs</p>
          <span class="box content has-background-light">
            <div
              v-for="(headerObject, index) in headers"
              :key="index + 'Valuations'"
            >
              <div
                class="column is-narrow"
                v-if="headerObject.type === 'Valuations'"
              >
                <span class="icon has-text-success">
                  <i class="far fa-check"></i>
                </span>
                <span class="has-text-grey">{{ headerObject.header }}</span>
              </div>
            </div>
            <div
              v-for="(headerObject, index) in headers"
              :key="index + 'Specs'"
            >
              <div
                class="column is-narrow"
                v-if="headerObject.type === 'Specs'"
              >
                <span class="icon has-text-success">
                  <i class="far fa-check"></i>
                </span>
                <span class="has-text-grey">{{ headerObject.header }}</span>
              </div>
            </div>
            <div
              v-for="(headerObject, index) in headers"
              :key="index + 'Keeper'"
            >
              <div
                class="column is-narrow"
                v-if="headerObject.type === 'Keeper'"
              >
                <span class="icon has-text-success">
                  <i class="far fa-check"></i>
                </span>
                <span class="has-text-grey">{{ headerObject.header }}</span>
              </div>
            </div>
            <div v-for="(headerObject, index) in headers" :key="index + 'Mot'">
              <div class="column is-narrow" v-if="headerObject.type === 'Mot'">
                <span class="icon has-text-success">
                  <i class="far fa-check"></i>
                </span>
                <span class="has-text-grey">{{ headerObject.header }}</span>
              </div>
            </div>
            <div
              v-for="(headerObject, index) in headers"
              :key="index + 'Listing'"
            >
              <div
                class="column is-narrow"
                v-if="headerObject.type === 'Listing'"
              >
                <span class="icon has-text-success">
                  <i class="far fa-check"></i>
                </span>
                <span class="has-text-grey">{{ headerObject.header }}</span>
              </div>
            </div>
          </span>
          <p />
          <span v-if="!allMatchesFound">
            <p class="is-size-5 has-text-info">No matches found</p>
            <p>
              Multi couldn't find matches for the following headers. Did you
              mean to use any of the suggested headers below?
            </p>
            <span class="box content has-background-light">
              <div
                v-for="(headerObject, index) in headers"
                :key="index + 'NotFound'"
              >
                <div
                  class="column is-narrow"
                  v-if="headerObject.found === false"
                >
                  <span class="icon has-text-danger">
                    <i class="far fa-times"></i>
                  </span>
                  <span
                    title="Multi doesn't support this header"
                    v-tippy
                    class="has-text-grey"
                    >{{ headerObject.header }}</span
                  >
                  <span
                    class="box content"
                    title="Did you mean to use one of these headers?"
                    v-tippy
                    v-if="headerObject.similarHeaders.length > 0"
                  >
                    <span
                      v-for="(header,
                      similarHeaderIndex) in headerObject.similarHeaders"
                      :key="similarHeaderIndex + header"
                    >
                      <span v-if="header !== headerObject.header">
                        <span class="icon has-text-success">
                          <i class="far fa-check"></i>
                        </span>
                        {{ header }}
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </span>
          </span>
          <br />
          <div class="buttons is-right">
            <button class="button is-soft is-rounded" @click="confirm">
              Confirm
            </button>
          </div>
        </div>
        <div v-if="duplicates" class="content">
          <p>
            Multi has found the following duplicate headers in your sheet.
            Please remove these to process the sheet:
          </p>
          <p class="is-size-5 has-text-info">Duplicate headers</p>
          <span class="box content has-background-light">
            <div
              v-for="(headerObject, index) in headers"
              :key="index + 'Inputs'"
            >
              <div class="column is-narrow" v-if="headerObject.duplicate">
                <span class="icon has-text-danger">
                  <i class="far fa-times"></i>
                </span>
                <span class="has-text-grey">{{ headerObject.header }}</span>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { confirm } from '../services'
export default {
  name: 'MultiHeaders',
  data: () => ({
    inputs: ['VRM'],
    duplicates: false,
    allMatchesFound: true,
    noInputs: true,
    noOutputs: true,
    noWorksheet: false
  }),
  computed: {
    ...mapGetters({
      headers: 'multi/headers',
      token: 'auth/token',
      fileName: 'modal/config'
    })
  },
  mounted() {
    const outputs = ['Valuations', 'Specs', 'Listing', 'Mot', 'Keeper']
    this.noWorksheet = this.headers.find(h => h === 'Could not find worksheet')
    this.noOutputs = !this.headers.some(h => outputs.includes(h.type))
    this.noInputs = !this.headers.some(h => h.type === 'Inputs')
    this.duplicates = this.headers.some(h => h.duplicate)
    this.allMatchesFound = this.headers.every(h => h.found)
    this.track()
  },
  methods: {
    async confirm() {
      try {
        await confirm(this.fileName, this.token)
        this.$store.dispatch('multi/fetchHistory')
        this.scroll()
      } finally {
        this.close()
      }
    },
    close() {
      this.$modal.close()
    },
    scroll() {
      const table = document.querySelector('#multi-history-table')
      if (table) table.scrollIntoView({ behavior: 'smooth' })
    },
    track() {
      const { noWorksheet, headers, $mxp } = this
      if (noWorksheet) {
        $mxp.track('multi_failed_no_worksheet')
      } else {
        const headerStrings = headers.map(header => header.header.toLowerCase())
        $mxp.track('multi_upload_headers', { detail: headerStrings })
      }
    }
  }
}
</script>
